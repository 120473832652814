import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’ve gone there once, the Citadel`}<br parentName="p"></br>{`
`}{`Citadel, the city of dreams`}<br parentName="p"></br>{`
`}{`The forest of concrete by day`}<br parentName="p"></br>{`
`}{`But when the buildings light up,`}<br parentName="p"></br>{`
`}{`Its a firework festival at night`}<br parentName="p"></br>{`
`}{`The Mecca of technology`}<br parentName="p"></br>{`
`}{`The land of hope`}<br parentName="p"></br>{`
`}{`The center of the world`}<br parentName="p"></br>{`
`}{`And the energy the city radiates,`}<br parentName="p"></br>{`
`}{`It’s indescribable, its fantastical`}<br parentName="p"></br>{`
`}{`All the brand stores, known to draw you in,`}<br parentName="p"></br>{`
`}{`give you a makeover, and send you out looking stunning`}<br parentName="p"></br>{`
`}{`The business district, famous for its millions`}<br parentName="p"></br>{`
`}{`and its smart, professional pace`}<br parentName="p"></br>{`
`}{`The hip, young avenues, full of life and bright,`}<br parentName="p"></br>{`
`}{`infamous for the rough people and the illegal trafficking`}<br parentName="p"></br>{`
`}{`The Citadel is loved and famed for its elegance and life,`}<br parentName="p"></br>{`
`}{`But is feared and notorious for the underworld empire`}<br parentName="p"></br>{`
`}{`that floods the city with the real money`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      